<template>
  <div class="shop-mask">
    <div
      class="shop-details is-scroll-x"
      :class="!isAnimation ? 'leaveAni': ''"
    >
      <div class="shop-title">
        {{titleList[viewVal - 1]}}
        <i
          class="el-icon-close"
          @click="hideShopDetails()"
        ></i>
      </div>
      <!-- 铺位信息-视图1、2、3、4、5、6 -->
      <div
        class="shop-info"
        v-if="viewVal === 1 || viewVal === 2 || viewVal === 3 || viewVal === 4 || viewVal === 5 || viewVal === 6"
      >
        <div class="shop-info-head">
          <h3 class="w-title-gradients">
            铺位信息
          </h3>
          <span
            class="fr"
            @click="toDetailPage(0)"
          >查看详情</span>
        </div>
        <div class="shop-info-detail">
          <p>
            <span>项目名称：{{dataObj.projectName || '--'}}</span>
            <span>楼宇楼层：{{dataObj.buildingFloor || '--'}}</span>
          </p>
          <p>
            <span>铺位号：{{dataObj.berthNo || '--'}}</span>
            <span>铺位状态：{{dataObj.berthStatus || '--'}}</span>
          </p>
          <p>
            <span>建筑面积(㎡)：{{dataObj.buildingArea || '--'}}</span>
            <span>使用面积(㎡)：{{dataObj.useArea || '--'}}</span>
          </p>
        </div>
      </div>
      <!-- 租决信息-视图1、2 -->
      <div
        class="lease-info"
        v-if="viewVal === 1 || viewVal === 2"
      >
        <div class="lease-info-head">
          <h3 class="w-title-gradients">
            <div class="w-input-sty no-padding-sel">
              <!-- <el-select
                v-if="leaseList.length > 0"
                popper-class="w-block-select-down"
                v-model="leaseId"
                style="width: 105px"
                @change="changelease"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in leaseList"
                  :key="item.i"
                  :label="item.label"
                  :value="item.i"
                >
                </el-option>
              </el-select> -->
              <span>租决信息</span>
            </div>
          </h3>
        </div>
        <div class="lease-info-detail">
          <p>
            <span><em>规划业态：</em>{{planBerthRentInfo.industry1?planBerthRentInfo.industry1:'-'}}</span>
            <span><em>主次力店：</em>{{planBerthRentInfo.mainStore === null ? '-' : planBerthRentInfo.mainStore === 0 ? '普通店' : (planBerthRentInfo.mainStore === 1 ? '主力店' : '次主力店')}}</span>
          </p>
          <p>
            <span><em>计租面积类型：</em>{{!planBerthRentInfo.areaType ? '-' : planBerthRentInfo.areaType === 1 ? '建筑面积' : '使用面积'}}</span>
            <span><em>计租面积：</em>{{planBerthRentInfo.area !== '-' && planBerthRentInfo.area ? planBerthRentInfo.area + '㎡':'-'}}</span>
          </p>
          <p>
            <span><em>租赁年限：</em>{{planBerthRentInfo.rentYear?planBerthRentInfo.rentYear: '-'}}</span>
            <span class="ellipsis"><em>租金递增率：</em>{{planBerthRentInfo.rentIncreasingRate?planBerthRentInfo.rentIncreasingRate:'-'}}</span>
          </p>
          <p>
            <span><em>租金市场价：</em>{{planBerthRentInfo.marketPrice?planBerthRentInfo.marketPrice + '元/日/㎡':'-'}}</span>
            <span><em>租金标准价：</em>{{planBerthRentInfo.rentPrice?planBerthRentInfo.rentPrice + '元/日/㎡':'-'}}</span>
          </p>
          <p>
            <span><em>租金保底价：</em>{{planBerthRentInfo.bottomPrice?planBerthRentInfo.bottomPrice + '元/日/㎡':'-'}}</span>
            <span><em>{{user.topuid === 6467 ? '物业' : '综合'}}管理费：</em>{{planBerthRentInfo.managementCost?planBerthRentInfo.managementCost + '元/日/㎡':'-'}}</span>
          </p>
          <p>
            <span><em>免租时长：</em>{{planBerthRentInfo.rentFreeTime?planBerthRentInfo.rentFreeTime:'-'}}</span>
            <span><em>招商品牌级次：</em>{{planBerthRentInfo.brandGradeType?planBerthRentInfo.brandGradeType:'-'}}</span>
          </p>
        </div>
      </div>
      <!-- 落位品牌信息-视图1 -->
      <div
        class="drop-info"
        v-if="viewVal === 1 && dataObj.berthStatus === '空置' && planBrands.length"
      >
        <div class="drop-info-head">
          <h3 class="w-title-gradients">
            落位品牌信息
          </h3>
          <span
            v-if="false"
            class="fr"
            @click="toDetailPage(6)"
          >管理品牌落位规划</span>
        </div>
        <div class="drop-brand-wrap">
          <div
            class="drop-brand-item"
            v-for="(item, i) in planBrands"
            :key="item.id"
          >
            <div class="drop-head">{{item.isFirst === 1? '首选品牌':'备选品牌'}}</div>
            <div
              class="drop-brand-info"
              @click="toDetails(item)"
            >
              <span class="sort-num">{{i+1}}</span>
              <img
                :src="item.brand.brandLogo"
                alt=""
              >
              <div class="name">
                <div class="brandName">{{item.brand.brandName}}</div>
                <div class="formatName">{{item.brand.industry}}</div>
                <div
                  v-if="item.task.taskState"
                  class="stateName"
                >{{item.task.taskState}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- 商铺租金/租金坪效-视图3 -->
      <!-- 当前铺位状态为已签约（正式合同）时，显示此模块。 -->
      <div
        class="rent-info"
        v-if="viewVal === 3 && Object.keys(rentInfo).length !== 0"
      >
        <div class="rent-info-head">
          <h3 class="w-title-gradients">
            商铺租金/租金坪效
            <el-tooltip
              class="item"
              effect="dark"
              content="租金统计本店铺关联的所有铺位"
              placement="right"
            >
              <span>
                <i class="icon-exclamation"></i>
              </span>
            </el-tooltip>
          </h3>
          <!-- <span class="fr">查看详情</span> -->
        </div>
        <div class="rent-info-detail">
          <p>
            <span>上月租金(万元)：{{rentInfo.lastMonthRent | hasData}}</span>
            <span>年度累计租金(万元)：{{rentInfo.yearRent | hasData}}</span>
          </p>
          <p>上月租金坪效(元/月/㎡)：{{rentInfo.lastMonthRentAreaAffect | hasData}}</p>
          <p>平均租金坪效(元/月/㎡)：{{rentInfo.monthlyRentAreaAffect | hasData}}</p>
          <p>与本楼层同业态商铺租金坪效对比：{{rentInfo.sameFloorIndustryRentAreaAffectContrast | contrastData}}</p>
          <p class="bg-tips">本楼层同业态商铺月均租金坪效(元/月/㎡)：{{rentInfo.sameFloorIndustryRentAreaAffect | hasData}}</p>
          <p>与本项目同业态商铺租金坪效对比：{{rentInfo.sameProjectIndustryRentAreaAffectContrast | contrastData}}</p>
          <p class="bg-tips">本项目同业态商铺月均租金坪效(元/月/㎡)：{{rentInfo.sameProjectIndustryRentAreaAffect | hasData}}</p>
        </div>
      </div>
      <!-- 商铺销售额/销售坪效-视图4 -->
      <div
        class="sale-info"
        v-if="viewVal === 4"
      >
        <div class="sale-info-head">
          <h3 class="w-title-gradients fl">
            商铺销售额/销售坪效
            <el-tooltip
              class="item"
              effect="dark"
              content="销售额统计本店铺关联的所有铺位"
              placement="right"
            >
              <span>
                <i class="icon-exclamation"></i>
              </span>
            </el-tooltip>
          </h3>
          <!-- <span class="fr">查看详情</span> -->
        </div>
        <div class="rent-info-detail">
          <p class="mt15">
            <span>本月销售额(万元)：{{salesInfo.currMonthSalesAmount | hasData}}</span>
            <span>上月销售额(万元)：{{salesInfo.lastMonthSalesAmount | hasData}}</span>
          </p>
          <p class="bg-tips2">环比：{{salesInfo.linkRelativeRatioOfLastMonth | hasData}}%</p>
          <p>本月销售坪效(元/月/㎡)：{{salesInfo.currMonthAreaAffect | hasData}}</p>
          <p>上月销售坪效(元/月/㎡)：{{salesInfo.lastMonthAreaAffect | hasData}}</p>
          <p>年度累计销售额(万元)：{{salesInfo.totalSalesAmount | hasData}}</p>
          <p>月均销售坪效(元/月/㎡)：{{salesInfo.monthlyAreaAffect | hasData}}</p>
          <p>与本项目同业态商铺销售坪效对比：{{salesInfo.sameFloorIndustryRentAreaAffectContrast | contrastData}}</p>
          <p class="bg-tips">本项目同业态商铺销售坪效(元/月/㎡)：{{salesInfo.sameProjectIndustryRentAreaAffect | hasData}}</p>
          <p>与本楼层同业态商铺销售坪效对比：{{salesInfo.sameProjectIndustryRentAreaAffectContrast | contrastData}}</p>
          <p class="bg-tips">本楼层同业态商铺销售坪效(元/月/㎡)：{{salesInfo.sameFloorIndustryRentAreaAffect | hasData}}</p>
          <p>
            <span>上月销售比：{{salesInfo.lastMonthSaleRentalRatio | hasData}}%</span>
            <span>年度平均租售比：{{salesInfo.yearSaleRentalRatio | hasData}}%</span>
          </p>
        </div>
      </div>
      <!-- 商铺账单收缴信息-视图5 -->
      <div
        class="seizure-info"
        v-if="viewVal === 5 && Object.keys(billsInfo).length"
      >
        <div class="seizure-info-head">
          <h3 class="w-title-gradients">
            商铺账单收缴信息
            <el-tooltip
              class="item"
              effect="dark"
              content="账单已计算本店铺关联的所有铺位"
              placement="right"
            >
              <span>
                <i class="icon-exclamation"></i>
              </span>
            </el-tooltip>
          </h3>
          <span
            class="fr"
            @click="toDetailPage(5)"
          >查看详情</span>
        </div>
        <div class="seizure-info-detail">
          <p>
            <span>上月应缴费用(万元)：{{billsInfo.monthReceiveMoney | hasData}}</span>
            <span>上月实缴费用(万元)：{{billsInfo.monthPayMoney | hasData}}</span>
          </p>
          <p class="bg-tips no-bg">
            <span style="opacity: 0;"></span>
            <span>实缴：{{billsInfo.actualPaymentRate | hasData}}%</span>
          </p>
          <p>
            <span>店铺当前欠费(万元)：{{billsInfo.totalNoReceiveMoney | hasData}}</span>
            <span>店铺费用收缴率：{{billsInfo.storeCaptureRate | hasData}}</span>
          </p>
          <p class="bg-tips">
            <span>本年度欠费（万元）：{{billsInfo.yearNoReceiveMoney | hasData}}</span>
            <span>本年度收缴率：{{billsInfo.yearCaptureRate | hasData}}%</span>
          </p>
          <p>
            <span>最长欠费账龄(天)：{{billsInfo.maxArrearsDay | hasData}}</span>
            <span>店铺保证金余额(元)：{{billsInfo.depositBalance | hasData}}</span>
          </p>
          <!-- <p>
            <span>铺位年度欠费(万元)：1999.99</span>
            <span>铺位累计欠费(万元)：2999.99</span>
          </p> -->
        </div>
      </div>
      <!-- 铺位空置留存信息-视图6 -->
      <div
        class="vacant-info"
        v-if="viewVal === 6 && Object.keys(vacancyInfo).length"
      >
        <div class="vacant-info-head">
          <h3 class="w-title-gradients">
            铺位空置留存信息
          </h3>
        </div>
        <div class="vacant-info-detail">
          <template v-if="vacancyInfo.status === 1 || vacancyInfo.status === 2">
            <p>
              <span>最近退场时间：{{vacancyInfo.recentlyStopDate | hasData}}</span>
            </p>
            <p>
              <span>已空置时长(天)：{{vacancyInfo.vacancyDays | hasData}}</span>
            </p>
            <p>
              <span>预计入场时间：{{vacancyInfo.predictEffectDate | hasData}}</span>
            </p>
          </template>
          <template v-if="vacancyInfo.status === 3">
            <p>
              <span>最近入场时间：{{vacancyInfo.recentlyEntranceDate | hasData}}</span>
            </p>
            <p>
              <span>已留存时长(天)：{{vacancyInfo.retentionDays | hasData}}</span>
            </p>
            <p>
              <span>预计退场时间：{{vacancyInfo.predictStopDate | hasData}}</span>
            </p>
          </template>
          <!-- <p>
            <span>最近5年平均留存时长(天)：400</span>
          </p>
          <p>
            <span>最近5年平均空置时长(天)：200</span>
          </p> -->
        </div>
      </div>
      <!-- 店铺信息-视图1、3、4、5、6 -->
      <div
        class="store-info"
        v-if="
          (viewVal === 1 ||
          viewVal === 3 ||
          viewVal === 4 ||
          viewVal === 5 ||
          viewVal === 6) &&
          Object.keys(storeInfo).length !== 0
          "
      >
        <div class="store-info-head">
          <h3 class="w-title-gradients">
            店铺信息
          </h3>
          <span
            class="fr"
            @click="toDetailPage(3)"
          >查看店铺详情</span>
        </div>
        <div class="store-info-detail">
          <p>
            <span>商户名称：{{storeInfo.merchantName | hasData}}</span>
            <span>店铺名称：{{storeInfo.storeName | hasData}}</span>
          </p>
          <p>
            <span>经营品牌：{{storeInfo.brandName | hasData}}</span>
            <span>经营业态：{{storeInfo.industryName | hasData}}</span>
          </p>
          <p>
            <span>店铺状态：{{storeInfo.storeStatus | hasData}}</span>
            <span>是否主力店铺：{{storeInfo.mainStoreOrNot | hasData}}</span>
          </p>
        </div>
      </div>
      <!-- 合同信息-视图2 -->
      <div
        class="contract-info"
        v-if="viewVal === 2 && Object.keys(contractInfo).length !== 0"
      >
        <div class="contract-info-head">
          <h3 class="w-title-gradients">
            合同信息
          </h3>
          <span
            class="fr"
            @click="toDetailPage(4)"
          >查看合同详情</span>
        </div>
        <div class="contract-info-detail">
          <p>
            合同号：{{contractInfo.contractCode | hasData}}
          </p>
          <p>
            <span>承租方：{{contractInfo.tenantry | hasData}}</span>
            <span>铺位号：{{contractInfo.berthNo | hasData}}</span>
          </p>
          <p>
            <span>店铺：{{contractInfo.storeName | hasData}}</span>
            <span>签约面积(㎡)：{{contractInfo.buildArea | hasData}}</span>
          </p>
          <p>
            <span>品牌：{{contractInfo.brandName | hasData}}</span>
            <span>经营业态：{{contractInfo.industryName | hasData}}</span>
          </p>
          <p>
            <span>合同类型：{{contractInfo.contractType | hasData}}</span>
            <span>合同状态：{{contractInfo.contractStatus | hasData}}</span>
          </p>
          <p>
            <span>合同生效日期：{{contractInfo.effectDate | hasData}}</span>
            <span>合同失效日期：{{contractInfo.cutoffDate | hasData}}</span>
          </p>
          <p>
            <span>合同租期：{{contractInfo.tenancy | hasData}}</span>
            <span>签约日期：{{contractInfo.signDate | hasData}}</span>
          </p>
          <p>
            <span>保证金(元)：{{contractInfo.depositMoney | hasData}}</span>
            <span>合同累计租金(万元)：{{contractInfo.contractRent | hasData}}</span>
          </p>
          <p>
            <span>
              与同期租决保底租金对比：{{contractInfo.samePeriodTargetRentContrast | contrastData}} <br>
            </span>
          </p>
          <p class="bg-tips">
            同期租决保底租金（元）：{{contractInfo.samePeriodTargetRent | hasData}}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import { getCookie } from '@/utils'
import { mapGetters } from 'vuex'
export default {
  data () {
    return {
      isAnimation: true,
      leaseList: [],
      leaseObj: {},
      titleList: ['业态规划详情', '合同租决详情', '租金贡献详情', '销售坪效详情', '账单收缴详情', '空置周期详情'],
      leaseId: 0,
      storeInfo: {},
      contractInfo: {},
      rentInfo: {},
      salesInfo: {},
      billsInfo: {},
      vacancyInfo: {},
      planBrands: [],
      planBerthRentInfo: {}
    }
  },
  props: {
    viewVal: {
      type: Number,
      default: 1
    },
    berthId: {
      type: String,
      default: ''
    },
    filtroProject: {
      type: Number,
      default: 0
    },
    dataObj: {
      type: Object,
      default: () => {
        return {}
      }
    },
    buildingName: {
      type: String,
      default: ''
    }
  },
  filters: {
    contrastData (num) {
      let res = ''
      console.log('num', num)
      switch (Number(num)) {
        case -1:
          res = '低'
          break
        case 0:
          res = '相等'
          break
        case 1:
          res = '高'
          break
        default:
          break
      }
      return res
    },
    hasData (num) {
      let res = ''
      if (num) {
        res = num
      } else {
        if (parseInt(num) === 0) {
          res = num
        } else {
          res = '--'
        }
      }
      return res
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  watch: {
    viewVal (n) {
    },
    berthId (n) {
    }
  },
  beforeDestroy () {
    window.removeEventListener('click', this.closeClick)
  },
  mounted () {
    window.addEventListener('click', this.closeClick)
    this.handleData()
  },
  methods: {
    toDetails (item) {
      const userAuthorities = this.user.confIds.filter(item => {
        return item.id === 164
      })
      if (userAuthorities.length > 0) {
        window.open(this.$gaodeDomain + '/omsviews/rent/assets/enterprise?menuCode=11&tokenval=' + getCookie('wisdom_token') + '&brandId=' + item.brand.brandId + '&gbid=' + item.brand.gbid + '&brandName=' + item.brand.brandName, '_blank')
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，您暂无查看企业品牌详情权限！'
        })
      }
    },
    toDetailPage (key) {
      let paramsObj = {}
      let routeName = ''
      switch (key) {
        case 0: // 店铺详情
          // routeName = 'berthInvestmentManageDetail'
          // paramsObj = {
          //   id: this.dataObj.berthId
          // }
          window.open(this.$gaodeDomain + '/omsviews/rent/assets/unit/details?menuCode=11&unitId=' + this.dataObj.berthId + '&tokenval=' + getCookie('wisdom_token'))
          break
        case 1: // 租决详情
          paramsObj = {
            id: this.leaseList[this.leaseId].id,
            berthId: this.leaseObj.berthId,
            targetId: this.leaseList[this.leaseId].targetId,
            projectId: this.filtroProject,
            type: 1, // 1查看 2编辑
            berthType: this.leaseObj.berthType,
            building: this.buildingName
          }
          routeName = 'investmentAimManageDetail'
          break
        case 2: // 添加铺位租决
          paramsObj = {
            id: this.leaseObj.id,
            berthId: this.leaseObj.berthId,
            projectId: this.filtroProject,
            type: 2, // 1查看 2编辑
            berthType: this.leaseObj.berthType,
            building: this.buildingName
          }
          if (this.leaseList.length) {
            paramsObj.targetId = this.leaseList[this.leaseId].targetId
          }
          routeName = 'investmentAimManageDetail'
          break
        case 3: // 查看店铺详情
          window.open(this.$gaodeDomain + '/omsviews/rent/assets/omsStore/details?menuCode=11&storeCode=' + this.storeInfo.storeCode + '&mallCode=' + this.filtroProject + '&tokenval=' + getCookie('wisdom_token'))
          break
        case 4: // 查看合同详情
          window.open(this.$gaodeDomain + '/omsviews/rent/contract/shopContract/details/mainBodyInfo?menuCode=12&contractCode=' + this.contractInfo.contractCode + '&contractId=' + this.contractInfo.contractId + '&mallCode=' + this.filtroProject + '&operateStep=3' + '&tokenval=' + getCookie('wisdom_token'))
          break
        case 5: // 商铺账单收缴信息 查看详情
          window.open(this.$gaodeDomain + '/omsviews/settle/billList?menuCode=5&tokenval=' + getCookie('wisdom_token'))
          break
        case 6: // 管理品牌落位规划
          paramsObj = {
            berthId: this.leaseObj.berthId
          }
          routeName = 'work'
          break
        default:
          break
      }
      if ([1, 2, 6].indexOf(key) !== -1) {
        const routerRes = this.$router.resolve({ name: routeName, query: paramsObj })
        window.open(routerRes.href, '_blank')
      }
    },
    handleData () {
      switch (this.viewVal) {
        case 1:
          this.getDataBrand()
          this.getDataStoreInfo()
          break
        case 2:
          this.getDataBrand()
          this.getDataContract()
          break
        case 3:
          this.getDataRent()
          this.getDataStoreInfo()
          break
        case 4:
          this.getDataStoreInfo()
          this.getDataSales()
          break
        case 5:
          this.getDataStoreInfo()
          this.getDataBills()
          break
        case 6:
          this.getDataStoreVacancy()
          this.getDataStoreInfo()
          break
        default:
          break
      }
    },
    closeClick (e) {
      if (e.target.className === 'shop-mask') {
        this.$emit('hideShopDetails')
      }
    },
    changelease () { },
    hideShopDetails () {
      this.isAnimation = false
      this.$emit('hideShopDetails')
    },
    getDataStoreInfo () {
      const params = {
        berthMapId: this.berthId,
        projectId: this.filtroProject
      }
      this.axios.post(api.storeInfo, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.storeInfo = {}
          if (resData) {
            this.storeInfo = resData
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataBrand () {
      const params = {
        berthId: this.berthId,
        decisionId: 0
      }
      this.axios.post(api.getMapBrandDetails, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (resData) {
            this.planBrands = resData.planBrands
            this.planBerthRentInfo = resData.planBerthRentInfo
            this.leaseObj.berthType = this.planBerthRentInfo.berthType
            this.leaseObj.berthId = this.planBerthRentInfo.berthId
            this.leaseObj.id = this.planBerthRentInfo.id
            // if (resDataLease.leaseVOList && resDataLease.leaseVOList.length) {
            //   this.leaseList = resDataLease.leaseVOList.map((item, i) => {
            //     item.i = i
            //     item.label = '租决信息' + (i + 1)
            //     return item
            //   })
            // }
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataRent () {
      const params = {
        berthMapId: this.berthId,
        projectId: this.filtroProject
      }
      this.axios.post(api.storeRentAndRentAreaAffect, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.rentInfo = {}
          if (resData) {
            this.rentInfo = resData
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataContract () {
      const params = {
        berthMapId: this.berthId,
        projectId: this.filtroProject
      }
      this.axios.post(api.contractInfo, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.contractInfo = {}
          if (resData) {
            this.contractInfo = resData
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataSales () {
      const params = {
        berthMapId: this.berthId,
        projectId: this.filtroProject
      }
      this.axios.post(api.storeSellAndSellAreaAffect, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          console.log('resData', resData)
          this.salesInfo = {}
          if (resData) {
            this.salesInfo = resData
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataBills () {
      const params = {
        berthMapId: this.berthId,
        projectId: this.filtroProject
      }
      this.axios.post(api.storeBillInfo, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.billsInfo = {}
          if (resData) {
            this.billsInfo = resData
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getDataStoreVacancy () {
      const params = {
        berthMapId: this.berthId,
        projectId: this.filtroProject
      }
      this.axios.post(api.storeVacancyRetentionInfo, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.vacancyInfo = {}
          if (resData) {
            this.vacancyInfo = resData
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    }
  }
}
</script>

<style lang="stylus" scoped>
.leaveAni
  animation keyLeft 0.5s ease 1 forwards
@keyframes keyLeft
  from
    right 0px
  to
    right -442px
@keyframes keyRight
  from
    right -442px
  to
    right 0px
.shop-mask
  position fixed
  width 100%
  height 100%
  left 0
  top 0
  bottom 0
  right 0
  background-color rgba(0, 0, 0, 0.6)
  z-index 11
  .fr
    margin-top 5px
    margin-right 20px
    margin-left auto
    font-size 12px
    color #FFA134
    font-weight 400
    cursor pointer
  .w-title-gradients
    font-weight 400
    .no-padding-sel>>>
      .el-input__inner
        padding 0
        font-size 16px
  .shop-details
    position absolute
    right -442px
    width 442px
    height 100%
    background rgba(39, 41, 48, 1)
    border-radius 2px
    animation keyRight 0.5s ease 1 forwards
    overflow-y auto
    .shop-title
      width 100%
      height 58px
      line-height 58px
      background-color rgba(255, 255, 255, 0.05)
      font-size 18px
      font-weight 500
      padding-left 24px
      box-sizing border-box
      .el-icon-close
        position absolute
        right 15px
        top 15px
        z-index 111
        cursor pointer
        font-size 24px
.shop-info
  margin-top 20px
  .shop-info-head
    display flex
  .shop-info-detail
    padding 24px
    p
      font-size 14px
      font-weight 400
      margin-bottom 14px
      span:first-child
        display inline-block
        width 65%
      span:last-child
        display inline-block
        width 35%
.lease-info
  .lease-info-head
    display flex
  .lease-info-detail
    padding 24px
    p
      font-size 14px
      font-weight 400
      margin-bottom 14px
      span:first-child
        display inline-block
        width 50%
      span:last-child
        display inline-block
        width 50%
.drop-info
  .drop-info-head
    display flex
  .drop-brand-wrap
    padding 24px 24px 0 24px
    height auto
    .drop-brand-item
      margin-bottom 24px
      .drop-head
        font-size 14px
        font-weight 400
      .drop-brand-info
        position relative
        width 100%
        height 77px
        background #fff
        margin-top 12px
        display flex
        align-items center
        cursor pointer
        img
          width 77px
          height 57px
          margin-left 10px
          margin-right 10px
          -o-object-fit cover
          object-fit cover
        .brandName
          font-size 14px
          color #333
          font-weight 500
          text-decoration underline
        .formatName
          font-size 12px
          color #999
          font-weight 500
          margin-top 5px
          margin-bottom 7px
        .stateName
          width 64px
          height 18px
          border-radius 2px
          color #fff
          background-color #01B3B0
          text-align center
          line-height 18px
          font-size 12px
          font-weight 500
        .sort-num
          position absolute
          left 2px
          top 2px
          width 18px
          height 18px
          background rgba(255, 161, 52, 0.8)
          color #fff
          line-height 18px
          text-align center
.store-info
  .store-info-head
    display flex
  .store-info-detail
    padding 24px
    p
      font-size 14px
      font-weight 400
      margin-bottom 14px
      span:first-child
        display inline-block
        width 60%
      span:last-child
        display inline-block
        width 40%
.contract-info
  .contract-info-head
    display flex
  .contract-info-detail
    padding 24px
    p
      font-size 14px
      font-weight 400
      margin-bottom 14px
      span:first-child
        display inline-block
        width 50%
      span:last-child
        display inline-block
        width 50%
.rent-info, .sale-info
  .rent-info-head
    display flex
  .rent-info-detail
    padding 24px
    p
      font-size 14px
      font-weight 400
      margin-bottom 14px
      span:first-child
        display inline-block
        width 50%
      span:last-child
        display inline-block
        width 50%
.no-bg
  background none !important
.bg-tips
  width 412px
  height 20px
  line-height 20px
  background linear-gradient(to right, rgba(243, 106, 52, 0.1), rgba(243, 106, 52, 0.01))
  font-size 12px !important
  color #F36A34
  margin-top -14px
  padding-left 5px
  span
    margin-top 0 !important
.bg-tips2
  width 412px
  height 20px
  line-height 20px
  background linear-gradient(to right, rgba(15, 201, 103, 0.1), rgba(15, 201, 103, 0.01))
  font-size 12px
  color #0FC967
  margin-top -14px
  padding-left 5px
.seizure-info
  .seizure-info-head
    display flex
  .seizure-info-detail
    padding 24px
    p
      font-size 14px
      font-weight 400
      margin-bottom 14px
      span:first-child
        display inline-block
        width 50%
      span:last-child
        display inline-block
        width 50%
    .bg-tips
      display flex
      span
        display inline-block
        width 40%
        height 20px
        line-height 20px
        background linear-gradient(to right, rgba(243, 106, 52, 0.1), rgba(243, 106, 52, 0.01))
        font-size 12px
        color #F36A34
        margin-top -14px
        padding-left 5px
.vacant-info
  .vacant-info-head
    display flex
  .vacant-info-detail
    padding 24px
    p
      font-size 14px
      font-weight 400
      margin-bottom 14px
      span
        display inline-block
.details-no-data
  margin 30px 0
.icon-exclamation
  &:before
    font-size 14px
    margin-left 3px
</style>
