<template>
  <div
    class="visual-store-analysis"
    v-loading="times<4"
  >
    <div v-if="hasRights">
      <div class="page-header">
        <div class="fl">
          <h1 class="page-title border-r">店铺可视化分析</h1>
          <span
            class="view-menu-title"
            @click.stop="handleViewMenuShow"
          >
            <i class="el-icon-menu"></i>
            {{viewMenu[viewVal-1].name}}
          </span>
          <div
            class="view-menu"
            v-show="showViewMenu"
            @click.stop=""
          >
            <h2>视图模式</h2>
            <ul>
              <li
                :class="{'active-item': viewMenu[viewVal-1].name === item.name}"
                v-for="item in viewMenu"
                :key="item.id"
                @click.stop="handleViewMenu(item)"
              >
                {{item.name}}
              </li>
            </ul>
          </div>
        </div>
        <div class="w-input-sty">
          <el-select
            popper-class="w-block-select-down"
            class="fr"
            v-model="filtroProject"
            size="small"
            placeholder="请选择"
            @change="handleData"
          >
            <el-option
              v-for="item in projectOptions"
              :key="item.id"
              :label="item.projectName"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </div>
        <div class="clearfix"></div>
      </div>
      <div class="page-body">
        <div class="fl">
          <div class="box-0 shadow-box">
            <h2 class="w-title-gradients">项目数据概览</h2>
            <ul class="info-list">
              <li
                v-for="(item,i) in dataOverviewItem[viewVal-1]"
                :key="i"
              >
                <p>
                  {{dataOverviewItem[viewVal-1][i]}}<br>
                  <span>{{dataOverviewValue[i] | convertErrorNum}}</span>
                  <span
                    class="xl-span block-span"
                    v-if="viewVal === 5 && i === 1"
                  >费用收缴率：{{dataOverviewValue[4] | convertErrorNum}}%</span>
                  <span
                    class="xl-span"
                    v-if="viewVal === 1 && i === 1"
                  >完成率：{{dataOverviewValue[4] | convertErrorNum}}%</span>
                  <span
                    class="xl-span"
                    v-if="viewVal === 1 && i === 3"
                  >完成率：{{dataOverviewValue[5] | convertErrorNum}}%</span>
                </p>
              </li>
            </ul>
          </div>
          <div class="box-0 shadow-box">
            <!-- part1 -->
            <h2
              v-if="viewVal!==5"
              class="w-title-gradients w-input-sty extra-style"
            >
              <el-select
                popper-class="w-block-select-down"
                :style="`width:${this.selectWidth[this.viewVal - 1][0]}px`"
                v-model="viewValSub1"
                @change="handleDataPanel1"
              >
                <el-option
                  v-for="item in viewMenuSub[viewVal-1][0]"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >{{item.label}}</el-option>
              </el-select>
            </h2>
            <h2
              v-else
              class="w-title-gradients"
            >{{viewMenuSub[viewVal-1][0]}}</h2>
            <div class="building-select w-input-sty extra-style xs-select">
              <el-select
                v-if="buildingList && buildingList.length"
                popper-class="w-block-select-down"
                v-model="buildingId1"
                @change="handleDataPanel1"
              >
                <el-option
                  v-for="item in buildingList"
                  :key="item.buildingCode"
                  :label="item.buildingName"
                  :value="item.buildingCode"
                >{{item.buildingName}}</el-option>
              </el-select>
            </div>
            <div
              id="chartsBoxA"
              class="charts-box"
              v-if="hasDataChartsA"
            ></div>
            <div
              class="details-no-data"
              v-else
            >
              <img
                src="@/assets/images/public/not-plandata.png"
                alt=""
              >
              <p>抱歉，暂无数据</p>
            </div>
          </div>
          <div class="box-0 shadow-box">
            <!-- part2 -->
            <h2
              v-if="viewVal===1 || viewVal===6"
              class="w-title-gradients w-input-sty extra-style"
            >
              <el-select
                popper-class="w-block-select-down"
                :style="`width:${this.selectWidth[this.viewVal - 1][1]}px`"
                v-model="viewValSub2"
                @change="handleDataPanel2"
              >
                <el-option
                  v-for="item in viewMenuSub[viewVal-1][1]"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >{{item.label}}</el-option>
              </el-select>
            </h2>
            <h2
              v-else
              class="w-title-gradients"
            >{{viewMenuSub[viewVal-1][1]}}</h2>
            <div class="building-select w-input-sty extra-style xs-select">
              <el-select
                v-if="buildingList && buildingList.length"
                popper-class="w-block-select-down"
                v-model="buildingId2"
                @change="handleDataPanel2"
              >
                <el-option
                  v-for="item in buildingList"
                  :key="item.buildingCode"
                  :label="item.buildingName"
                  :value="item.buildingCode"
                >{{item.buildingName}}</el-option>
              </el-select>
            </div>
            <div
              id="chartsBoxB"
              class="charts-box"
              v-if="hasDataChartsB"
            ></div>
            <div
              class="details-no-data"
              v-else
            >
              <img
                src="@/assets/images/public/not-plandata.png"
                alt=""
              >
              <p>抱歉，暂无数据</p>
            </div>
          </div>
          <div class="box-0 box-higher shadow-box">
            <!-- part3 -->
            <h2
              v-if="viewVal===2 || viewVal===3 || viewVal===4 || viewVal===6"
              class="w-title-gradients w-input-sty extra-style"
            >
              <el-select
                popper-class="w-block-select-down"
                :style="`width:${this.selectWidth[this.viewVal - 1][2][viewValSub3]}px`"
                v-model="viewValSub3"
                @change="handleDataPanel3"
              >
                <el-option
                  v-for="item in viewMenuSub[viewVal-1][2]"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                >{{item.label}}</el-option>
              </el-select>
            </h2>
            <h2
              v-else
              class="w-title-gradients"
            >{{viewMenuSub[viewVal-1][2]}}</h2>
            <div class="building-select w-input-sty extra-style xs-select">
              <el-select
                v-if="buildingList && buildingList.length"
                popper-class="w-block-select-down"
                v-model="buildingId3"
                @change="handleDataPanel3"
              >
                <el-option
                  v-for="item in buildingList"
                  :key="item.buildingCode"
                  :label="item.buildingName"
                  :value="item.buildingCode"
                >{{item.buildingName}}</el-option>
              </el-select>
            </div>
            <el-table
              v-if="merchantsScheduleTbs.length>0 && viewVal === 1"
              class="tbs-black tbs-wrap is-scroll-x tbs-th-light"
              :data="merchantsScheduleTbs"
            >
              <el-table-column
                prop="state"
                label="招商进度"
                width=""
              >
              </el-table-column>
              <el-table-column
                prop="berthNum"
                label="铺位数量"
                width=""
              >
              </el-table-column>
              <el-table-column
                prop="berthArea"
                label="铺位面积(㎡)"
                width=""
              >
              </el-table-column>
            </el-table>
            <template v-else-if="hasDataChartsC && viewVal !== 1">
              <div
                id="chartsBoxC"
                class="charts-box"
              ></div>
              <div
                id="tipbox"
                class="tipname hide"
              ></div>
            </template>
            <div
              class="details-no-data"
              v-else
            >
              <img
                src="@/assets/images/public/not-plandata.png"
                alt=""
              >
              <p>抱歉，暂无数据</p>
            </div>
          </div>
        </div>
        <div class="fr box-main">
          <map-view
            v-if="filtroProject && times > 3"
            :filtroProject="filtroProject"
            :viewVal="viewVal"
            @setBuildingList="getBuildingList"
          ></map-view>
        </div>
        <div class="clearfix"></div>
      </div>
    </div>
    <div v-else>
      <div class="jurisdiction-wrap">
        <img
          src="~@/assets/images/Jurisdiction.png"
          alt=""
        >
        <p>暂无权限访问此页，如需开通权限，</p>
        <p>请联系管理员开通</p>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/api'
import mapView from '@/views/biModules/components/mapView.vue'
import echarts from 'echarts'
import { mapGetters } from 'vuex'
export default {
  name: 'visualStoreAnalysis',
  components: {
    mapView
  },
  data () {
    return {
      times: 0,
      viewMenu: [
        { name: '招商规划视图', value: 1, id: 174 },
        { name: '合同租决视图', value: 2, id: 175 },
        { name: '租金坪效视图', value: 3, id: 176 },
        { name: '销售坪效视图', value: 4, id: 177 },
        { name: '账单欠费视图', value: 5, id: 178 },
        { name: '空置留存视图', value: 6, id: 179 }
      ],
      buildingId1: 0,
      buildingId2: 0,
      buildingId3: 0,
      buildingList: [],
      selectWidth: [
        [
          '130', '130', ''
        ],
        [
          '', '130', ['130', '162']
        ],
        [
          '130', '', ['165', '195']
        ],
        [
          '130', '', ['180', '195']
        ],
        [
          '', '', ['']
        ],
        [
          '165', '130', ['165']
        ]
      ],
      viewVal: 1,
      viewValSub1: 0, // 每个模块左侧的select（如果有的话）
      viewValSub2: 0,
      viewValSub3: 0,
      dataOverviewValue: [],
      showViewMenu: false,
      filtroProject: '',
      projectOptions: [],
      chartsA: null, // 每个模块的图表实例（如果是的话）
      chartsB: null,
      chartsC: null,
      hasDataChartsA: true,
      hasDataChartsB: true,
      hasDataChartsC: true,
      merchantsScheduleTbs: [],
      viewMenuSub: [
        [
          [
            { value: 0, label: '楼层面积达成' },
            { value: 1, label: '楼层铺位达成' }
          ],
          [
            { value: 0, label: '业态面积达成' },
            { value: 1, label: '业态铺位达成' }
          ],
          '招商进度统计'
        ],
        [
          [
            { value: 0, label: '楼层租金达成' },
            { value: 1, label: '业态租金达成' }
          ],
          '年度租金完成进度',
          [
            { value: 0, label: '合同周期分析' },
            { value: 1, label: '合同剩余时长统计' }
          ]
        ],
        [
          [
            { value: 0, label: '楼层租金贡献' },
            { value: 1, label: '业态租金贡献' }
          ],
          '年度租金坪效',
          [
            { value: 0, label: '上月店铺租金排行' },
            { value: 1, label: '上月店铺租金坪效排行' }
          ]
        ],
        [
          [
            { value: 0, label: '楼层销售坪效' },
            { value: 1, label: '业态销售坪效' }
          ],
          '本年销售/客流趋势',
          [
            { value: 0, label: '本月店铺销售额排行' },
            { value: 1, label: '本月店铺销售坪效排行' }
          ]
        ],
        ['费用收缴分析', '欠费账龄分析', '店铺欠费金额排行'],
        [
          [
            { value: 0, label: '楼层空置留存周期' },
            { value: 1, label: '业态空置留存周期' }
          ],
          [
            { value: 0, label: '铺位空置周期' },
            { value: 1, label: '铺位留存周期' }
          ],
          [
            { value: 0, label: '铺位空置周期排行' },
            { value: 1, label: '铺位留存周期排行' }
          ]
        ]
      ],
      dataOverviewItem: [
        ['总铺位数量', '已租铺位', '总铺位面积（㎡）', '已签约面积（㎡）'],
        ['已签约年度租金（万元）', '目标年度租金收益（万元）', '租金目标完成率', '临期商铺数量'],
        ['已租面积（㎡）', '年度累计租金总额（万元）', '上月租金（万元）', '上月租金坪效（元/㎡）'],
        ['本月销售额（万元）', '本月销售坪效（元/月/㎡）', '年度销售额（万元）', '年度销售坪效（元/㎡）'],
        ['上月应收金额（万元）', '上月实收金额（万元）', '当前欠费店铺数', '累计欠费（万元）'],
        ['入驻率', '空置铺位', '平均空置周期（天）', '平均留存周期（天）']
      ],
      rightsList: [],
      hasRights: true,
      isLoading: ''
    }
  },
  computed: {
    ...mapGetters({
      user: 'user'
    })
  },
  filters: {
    convertErrorNum: (num) => {
      if (typeof num === 'number') {
        return num === 0 || num ? num : '--'
      } else if (typeof num === 'string') {
        if (num && num.indexOf('null') === -1) {
          return num
        } else {
          return '--'
        }
      } else {
        return '--'
      }
    }
  },
  mounted () {
    const rightsList = this.user.confIds.filter(item => {
      return [174, 175, 176, 177, 178, 179].indexOf(item.id) !== -1
    }).sort((a, b) => {
      return a.id - b.id
    })
    for (let i = 0; i < rightsList.length; i++) { // 设置拥有权限的项的id组成的数组，用于切换视图时判断有无权限
      this.rightsList.push(rightsList[i].id)
    }
    if (this.rightsList.length) { // 设置账号拥有权限的第一个视图
      this.viewVal = this.rightsList[0] - 173 // 这几个视图的id是固定的，这样算刚好是有权限的第一个视图
      // this.viewVal = 2
      this.getDataProjList()
    } else {
      this.hasRights = false
      document.getElementsByClassName('visual-store-analysis')[0].style.minHeight = 'auto'
    }
  },
  methods: {
    handleViewMenuShow () { // 视图模式窗口显隐
      this.showViewMenu = !this.showViewMenu
      if (this.showViewMenu) {
        const f = () => {
          this.showViewMenu = false
          document.body.onclick = ''
        }
        document.body.onclick = f
      }
    },
    getBuildingList (list) {
      this.buildingList = list.map(item => {
        return item
      })
      this.buildingList.unshift({
        buildingName: '所有楼宇',
        buildingCode: 0
      })
    },
    resetConfig () {
      if (this.chartsA) {
        this.chartsA.dispose()
        this.chartsA = null
      }
      this.hasDataChartsA = true
      if (this.chartsB) {
        this.chartsB.dispose()
        this.chartsB = null
      }
      if (this.chartsC) {
        this.chartsC.dispose()
        this.chartsC = null
      }
      this.times = 0
      this.viewValSub1 = 0
      this.viewValSub2 = 0
      this.viewValSub3 = 0
      this.buildingId1 = 0
      this.buildingId2 = 0
      this.buildingId3 = 0
      this.dataOverviewValue = []
    },
    handleViewMenu (item) {
      if (this.rightsList.indexOf(item.id) !== -1) {
        this.viewVal = item.value
        this.showViewMenu = false
        this.handleData()
      } else {
        this.$message({
          type: 'warning',
          message: '抱歉，此账号暂无权限！'
        })
      }
    },
    handleData () { // 切换项目、视图时执行
      this.updateProjectIdFunc()
      this.resetConfig()
      const apiNameList = [
        'merchantsPlanDateview',
        'contractDataView',
        'dataOverviewRent',
        'dataOverviewSales',
        'dataOverviewOverdue',
        'dataOverviewVacancy'
      ]
      const fieldList = [
        ['totalBerthNum', 'rentBerthNum', 'totalBerthArea', 'signBerthArea', 'berthOccupancyRate', 'berthRentOccupancyRate'],
        ['signyearRent', 'aimsyearRent', 'aimsrentRate', 'adventBerthNum'],
        ['rentedArea', 'yearTotalRent', 'lastMonthRent', 'lastMonthRentAreaEffect'],
        ['monthSale', 'monthSaleAreaAffect', 'yearSale', 'monthlySaleAreaAffect'],
        ['totalReceiveMoney', 'totalPayMoney', 'arrearsNum', 'totalNoReceiveMoney', 'captureRate'], // captureRate：2-1 费用收缴率
        ['berthRentOurRate', 'vacancyBerthNums', 'avgVacancyPeriod', 'avgRetentionPeriod']
      ]
      // 项目数据概览
      const params = {
        projectId: this.filtroProject
        // type: 0 // 地图类型：0 [规划业态, 合同租决, 租金贡献, 销售额, 账单收缴, 空置周期]; 1 [招商进度, 合同时长, 租金坪效, 销售坪效, 欠费金额, 留存周期]; 2 [租售比, 欠费账龄]
      }
      this.axios.post(api[apiNameList[this.viewVal - 1]], params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (resData) {
            this.dataOverviewValue.push(resData[fieldList[this.viewVal - 1][0]])
            this.dataOverviewValue.push(resData[fieldList[this.viewVal - 1][1]])
            this.dataOverviewValue.push(resData[fieldList[this.viewVal - 1][2]])
            this.dataOverviewValue.push(resData[fieldList[this.viewVal - 1][3]])
            if (this.viewVal === 2) {
              this.dataOverviewValue[2] += '%'
            }
            if (this.viewVal === 6) {
              this.dataOverviewValue[0] += '%'
            }
            if (this.viewVal === 5) {
              this.dataOverviewValue.push(resData[fieldList[this.viewVal - 1][4]])
            }
            if (this.viewVal === 1) {
              this.dataOverviewValue.push(resData[fieldList[this.viewVal - 1][4]])
              this.dataOverviewValue.push(resData[fieldList[this.viewVal - 1][5]])
            }
          }
          this.times++
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
      this.handleDataPanel1()
      this.handleDataPanel2()
      this.handleDataPanel3()
    },
    getDataProjList () { // select项目列表
      this.loading = true
      this.axios.post(api.getBerthProjectList, {
      }).then(res => {
        if (res.data.code === 0) {
          this.projectOptions = res.data.data
          this.getProjectIdFunc().then((res) => {
            this.filtroProject = res.data.data.projectId
            this.handleData() // 拿到第一个项目id再调用接口
          })
        }
        this.loading = false
      })
    },
    updateProjectIdFunc () {
      const params = {
        projectId: this.filtroProject
      }
      this.axios.post(api.updateProjectId, params)
        .then((res) => {
        })
    },
    getProjectIdFunc () {
      return new Promise((resolve, reject) => {
        this.axios.get(api.getProjectId)
          .then((res) => {
            if (res.status === 200) {
              resolve(res)
            } else {
              reject(new Error(''))
            }
          })
      })
    },
    handleDataPanel1 () { // setData1
      const apiNameList = [
        [
          'floorArea',
          'floorBerth'
        ],
        [
          'floorRentReach',
          'industryRentReach'
        ],
        [
          'floorRentContribution',
          'industryRentContribution'
        ],
        [
          'floorSellAreaAffect',
          'industrySellAreaAffect'
        ],
        [
          'costCollectionAnalysis',
          ''
        ],
        [
          'floorVacancyRetentionPeriod',
          'industryVacancyRetentionPeriod'
        ]
      ]
      const params = {
        buildingCode: this.buildingId1 ? this.buildingId1 : '',
        projectId: this.filtroProject
      }
      let count = 0
      this.axios.post(api[apiNameList[this.viewVal - 1][this.viewValSub1]], params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          const seriesData = resData.series.map(item => {
            for (let i = 0; i < item.data.length; i++) {
              count += Number(item.data[i])
            }
            return item
          })
          if (count !== 0) {
            this.hasDataChartsA = true
            this.$nextTick(() => {
              if (!this.chartsA) {
                this.chartsA = echarts.init(document.getElementById('chartsBoxA'))
                this.chartsA.clear()
                this.chartsA.setOption(this.getChartsOptions())
                switch (this.viewVal) {
                  case 1:
                    this.chartsA.setOption(this.getChartsOptionsType0(2))
                    break
                  case 2:
                    this.chartsA.setOption(this.getChartsOptionsType0(1))
                    break
                  case 3:
                    this.chartsA.setOption(this.getChartsOptionsType1())
                    this.chartsA.setOption({
                      legend: [
                        {
                          data: [resData.series[0].name],
                          left: 150,
                          itemWidth: 12,
                          itemHeight: 12
                        },
                        {
                          bottom: 0,
                          left: 225,
                          textStyle: {
                            color: 'rgba(255,255,255,1)',
                            fontSize: 12
                          },
                          data: [resData.series[1].name],
                          itemWidth: 25,
                          itemHeight: 12
                        }
                      ],
                      yAxis: [
                        {
                          name: '万元'
                        },
                        {
                          name: '租金坪效/(元/月/㎡)'
                        }
                      ]
                    })
                    break
                  case 4:
                    this.chartsA.setOption(this.getChartsOptionsType1())
                    this.chartsA.setOption({
                      legend: [
                        {
                          data: [resData.series[0].name],
                          left: 150,
                          itemWidth: 12,
                          itemHeight: 12
                        },
                        {
                          bottom: 0,
                          left: 225,
                          textStyle: {
                            color: 'rgba(255,255,255,1)',
                            fontSize: 12
                          },
                          data: [resData.series[1].name],
                          itemWidth: 25,
                          itemHeight: 12
                        }
                      ],
                      yAxis: [
                        {
                          name: '销售额/万元'
                        },
                        {
                          name: '销售坪效(元/月/㎡)'
                        }
                      ],
                      tooltip: {}
                    })
                    break
                  case 5:
                    this.chartsA.setOption(this.getChartsOptionsType1(1, 1))
                    this.chartsA.setOption({
                      legend: [
                        {
                          data: [resData.series[0].name, resData.series[1].name],
                          left: 117,
                          itemWidth: 12,
                          itemHeight: 12
                        },
                        {
                          bottom: 0,
                          left: 267,
                          textStyle: {
                            color: 'rgba(255,255,255,1)',
                            fontSize: 12
                          },
                          data: [resData.series[2].name],
                          itemWidth: 25,
                          itemHeight: 12
                        }
                      ],
                      tooltip: {
                        formatter: (params) => {
                          let html = ''
                          html += `<b>${params[0].name}</b><br>`
                          for (let i = 0; i < params.length; i++) {
                            html += `${params[i].marker + params[i].seriesName}：${params[i].value}`
                            html += `${params[i].seriesName.indexOf('率') > 0 ? '%' : '万元'}<br>`
                          }
                          return html
                        }
                      },
                      yAxis: [{}, { name: '' }]
                    })
                    break
                  case 6:
                    this.chartsA.setOption(this.getChartsOptionsType0(3))
                    this.chartsA.setOption(
                      {
                        grid: {
                          right: '50'
                        },
                        yAxis: [
                          {
                            name: '空置/天'
                          },
                          {
                            type: 'value',
                            name: '留存/天',
                            nameTextStyle: {
                              color: 'rgba(255,255,255,.45)'
                            },
                            axisLabel: {
                              color: 'rgba(255,255,255,.45)'
                            },
                            splitLine: { // 在grid的分割线
                              lineStyle: {
                                color: 'rgba(255,255,255,.1)'
                              }
                            },
                            axisTick: { // 坐标刻度
                              show: false
                            }
                          }
                        ],
                        series: [
                          {
                            yAxisIndex: 0
                          },
                          {
                            yAxisIndex: 1
                          }
                        ]
                      }
                    )
                    break
                  default:
                    break
                }
                window.addEventListener('resize', () => {
                  this.chartsA.resize()
                })
              }
              if (this.chartsA) {
                this.chartsA.setOption({
                  xAxis: {
                    data: resData.labels
                  },
                  series: seriesData
                })
                if (this.viewVal === 1) {
                  const unitList = ['㎡', '个']
                  this.chartsA.setOption({
                    yAxis: {
                      name: unitList[this.viewValSub1]
                    },
                    tooltip: {
                      formatter: (params) => {
                        let html = ''
                        html += `<b>${params[0].name}</b><br>`
                        for (let i = 0; i < params.length; i++) {
                          html += `${params[i].marker + params[i].seriesName}：${params[i].value + unitList[this.viewValSub1]}<br>`
                        }
                        return html
                      }
                    }
                  })
                } else if (this.viewVal === 6) {
                  let Max1, Max2
                  if (seriesData && seriesData.length) {
                    Max1 = this.calMax(seriesData[0].data)
                    Max2 = this.calMax(seriesData[1].data)
                  }
                  const maxVal = Math.max(Max1, Max2)
                  this.chartsA.setOption({
                    yAxis: [
                      {
                        min: 0,
                        max: maxVal
                      },
                      {
                        min: 0,
                        max: maxVal
                      }
                    ]
                  })
                }
                this.isSetDataZoom(resData.labels, 0)
              }
            })
          } else {
            if (this.chartsA) {
              this.chartsA.dispose()
              this.chartsA = null
            }
            this.hasDataChartsA = false
          }
          this.times++
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    isSetDataZoom (data, type) { // type:chartsA 0 chartsB 1
      const tempBool = (data && data.length > 5)
      if (!tempBool) { return }
      if (tempBool) {
        let tempObj = {}
        const legendArr = []
        tempObj = {
          xAxis: {
            axisLabel: { interval: 0 }
          },
          grid: {
            top: 40,
            bottom: 55
          },
          legend: [],
          dataZoom: [
            {
              type: 'slider',
              show: true,
              xAxisIndex: [0],
              bottom: 3,
              start: 0,
              end: 45,
              height: 6,
              moveHandleSize: 2,
              backgroundColor: 'none',
              fillerColor: '#3c3e44',
              textStyle: false,
              borderColor: 'none',
              handleStyle: {
                color: '#3c3e44'
              },
              handleIcon: 'path://M512,512m-448,0a448,448,0,1,0,896,0a448,448,0,1,0,-896,0Z'
            },
            {
              type: 'inside',
              xAxisIndex: [0],
              start: 1,
              end: 45
            }
          ]
        }
        if (type === 0) {
          for (let i = 0; i < this.chartsA.getOption().legend.length; i++) {
            legendArr.push({
              bottom: 7
            })
          }
          tempObj.legend = legendArr
          this.chartsA.setOption(tempObj)
        } else {
          for (let i = 0; i < this.chartsB.getOption().legend.length; i++) {
            legendArr.push({
              bottom: 7
            })
          }
          tempObj.legend = legendArr
          this.chartsB.setOption(tempObj)
        }
      }
    },
    handleDataPanel2 () { // setData2
      const apiNameList = [
        [
          'industryArea',
          'industryBerth'
        ],
        [
          'yearRentCompleteRate'
        ],
        [
          'yearMonthlyRentAreaEffect'
        ],
        [
          'yearSellAndPassengerFlowTendency'
        ],
        ['arrearsAgingAnalysis'],
        [
          'berthVacancyPeriod',
          'berthRetentionPeriod'
        ]
      ]
      const params = {
        buildingCode: this.buildingId2 ? this.buildingId2 : '',
        projectId: this.filtroProject
      }
      this.axios.post(api[apiNameList[this.viewVal - 1][this.viewValSub2]], params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (this.chartsB) {
            this.chartsB.dispose()
            this.chartsB = null
          }
          if (
            (resData.labels && resData.labels.length === 0) ||
            (resData.values && resData.values.length === 0)
          ) {
            this.hasDataChartsB = false
          } else {
            this.hasDataChartsB = true
            this.$nextTick(() => {
              this.chartsB = echarts.init(document.getElementById('chartsBoxB'))
              this.chartsB.clear()
              window.addEventListener('resize', () => {
                this.chartsB.resize()
              })
              if (this.viewVal !== 6 && this.viewVal !== 5) {
                this.chartsB.setOption(this.getChartsOptions())
                switch (this.viewVal) {
                  case 1:
                    this.chartsB.setOption(this.getChartsOptionsType0(2))
                    break
                  case 2:
                    this.chartsB.setOption(this.getChartsOptionsType1(0, 1))
                    this.chartsB.setOption({
                      legend: [
                        {
                          data: [resData.series[0].name],
                          left: 150,
                          itemWidth: 12,
                          itemHeight: 12
                        },
                        {
                          bottom: 0,
                          left: 225,
                          textStyle: {
                            color: 'rgba(255,255,255,1)',
                            fontSize: 12
                          },
                          data: [resData.series[1].name],
                          itemWidth: 25,
                          itemHeight: 12
                        }
                      ]
                    })
                    break
                  case 3:
                    this.chartsB.setOption(this.getChartsOptionsType1())
                    this.chartsB.setOption({
                      yAxis: [
                        {
                          name: '万元'
                        },
                        {
                          name: '租金坪效/(元/月/㎡)'
                        }
                      ]
                    })
                    break
                  case 4:
                    this.chartsB.setOption(this.getChartsOptionsType0(1))
                    this.chartsB.setOption({
                      tooltip: {
                        formatter: (params) => {
                          let html = ''
                          html += `<b>${params[0].name}</b><br>`
                          for (let i = 0; i < params.length; i++) {
                            html += `${params[i].marker + params[i].seriesName}：${params[i].value} ${params[i].seriesName === '客流' ? '万人' : '万元'}<br>`
                          }
                          return html
                        }
                      },
                      yAxis: [
                        {
                          name: '万元'
                        },
                        {
                          type: 'value',
                          name: '万人',
                          nameTextStyle: {
                            color: 'rgba(255,255,255,.45)'
                          },
                          axisLabel: {
                            color: 'rgba(255,255,255,.45)'
                          },
                          splitLine: { // 在grid的分割线
                            lineStyle: {
                              color: 'rgba(255,255,255,.1)'
                            }
                          },
                          axisTick: { // 坐标刻度
                            show: false
                          }
                        }
                      ]
                    })
                    break
                  default:
                    break
                }
                this.chartsB.setOption({
                  xAxis: {
                    data: resData.labels
                  },
                  series: resData.series ? resData.series : resData.values
                })
                this.isSetDataZoom(resData.labels, 1)
                if (this.viewVal === 1) {
                  const unitList = ['㎡', '个']
                  this.chartsB.setOption({
                    yAxis: {
                      name: unitList[this.viewValSub2]
                    },
                    tooltip: {
                      formatter: (params) => {
                        let html = ''
                        html += `<b>${params[0].name}</b><br>`
                        for (let i = 0; i < params.length; i++) {
                          html += `${params[i].marker + params[i].seriesName}：${params[i].value + unitList[this.viewValSub2]}<br>`
                        }
                        return html
                      }
                    }
                  })
                }
              } else {
                this.getChartsOptionsType4(resData)
              }
            })
          }
          this.times++
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    handleDataPanel3 () {
      const apiNameList = [
        [
          'merchantsProgressCount'
        ],
        [
          'pactanalysis',
          'pactduration'
        ],
        [
          'lastMonthStoreRentRank',
          'lastMonthStoreRentAreaAffectRank'
        ],
        [
          'monthStoreSellRank',
          'monthStoreSellAreaAffect'
        ],
        ['storeArrearsAmountRank'],
        [
          'berthVacancyPeriodRank',
          'berthRetentionPeriodRank'
        ]
      ]
      const params = {
        buildingCode: this.buildingId3 ? this.buildingId3 : '',
        projectId: this.filtroProject
      }
      this.axios.post(api[apiNameList[this.viewVal - 1][this.viewValSub3]], params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          if (this.viewVal === 1) {
            this.merchantsScheduleTbs = resData.map(item => {
              return item
            })
          } else {
            if (
              (resData.labels && resData.labels.length === 0) ||
              (resData.values && resData.values.length === 0) ||
              (resData.series && resData.series.length === 0)
            ) {
              if (this.chartsC) {
                this.chartsC.dispose()
                this.chartsC = null
              }
              this.hasDataChartsC = false
            } else {
              this.hasDataChartsC = true
              this.$nextTick(() => {
                if (!this.chartsC) {
                  this.chartsC = echarts.init(document.getElementById('chartsBoxC'))
                  this.chartsC.clear()
                  this.chartsC.setOption(this.getChartsOptions())
                  window.addEventListener('resize', () => {
                    this.chartsC.resize()
                  })
                }
                if (this.chartsC) {
                  if (this.viewVal === 2) {
                    const dataTemplate = {
                      type: 'bar',
                      stack: 'total',
                      barWidth: 14,
                      label: {
                        // show: true
                      },
                      emphasis: {
                        focus: 'series'
                      }
                    }
                    const seriesList = resData.series.map(item => {
                      Object.assign(item, dataTemplate)
                      return item
                    })
                    this.chartsC.setOption({
                      legend: {
                        bottom: '-5',
                        textStyle: {
                          color: 'rgba(255,255,255,1)',
                          fontSize: 12
                        },
                        itemWidth: 12,
                        itemHeight: 12,
                        type: 'plain'
                      },
                      tooltip: {
                        trigger: 'axis',
                        axisPointer: {
                          type: 'shadow'
                        }
                      },
                      grid: {
                        top: 10,
                        bottom: 60,
                        left: 70
                      },
                      color: ['#1A8CF2', '#0FC967', '#1BD2FB', '#661CF4', '#B74EDB', '#FB6C14', '#FFA134', '#15FFA4'],
                      xAxis: {
                        type: 'value',
                        axisLabel: {
                          color: 'rgba(255,255,255,.45)'
                        },
                        splitLine: { // 在grid的分割线
                          show: false
                        },
                        axisTick: { // 坐标刻度
                          show: false
                        }
                      },
                      yAxis: {
                        type: 'category',
                        axisLabel: {
                          color: 'rgba(255,255,255,.45)'
                        },
                        data: resData.labels,
                        axisTick: {
                          show: false
                        }
                      },
                      series: seriesList
                    })
                  } else {
                    this.chartsC.setOption(this.getChartsOptionsType3())
                    if (this.viewVal === 6) {
                      this.chartsC.setOption({
                        xAxis: {
                          name: '天'
                        }
                      })
                    }
                    this.chartsC.setOption({
                      yAxis: {
                        data: resData.labels.reverse(),
                        triggerEvent: true,
                        axisLabel: {
                          interval: 0,
                          formatter: function (params) { // 标签输出形式 ---请开始你的表演
                            if (params.length > 9) { return params.substring(0, 9) + '...' } else { return params }
                          }
                        }
                      },
                      tooltip: {
                        formatter: '{b}：{c} ' + (this.viewVal === 6 ? '天' : '万元')
                      },
                      series: {
                        data: resData.values.reverse()
                      }
                    })
                    this.chartsC.on('mouseover', function (params) { // 鼠标经过图表时候判断参数  ---请开始你的表演
                      console.log(params)
                      if (params.componentType === 'yAxis') {
                        const tt = document.getElementById('tipbox')
                        tt.innerText = params.value
                        tt.style.left = params.event.event.layerX + 10 + 'px'
                        tt.style.top = params.event.event.layerY + 20 + 'px'
                        tt.style.display = 'block'
                      }
                    })
                    this.chartsC.on('mouseout', function (params) {
                      document.getElementById('tipbox').style.display = 'none'
                    })
                  }
                }
              })
            }
          }
          setTimeout(() => {
            this.times++
          }, 600)
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getChartsOptions () { // 最基本的echarts设置
      const option = {
        grid: {
          top: 50,
          right: 30,
          bottom: 50,
          left: 50
        },
        legend: {
          bottom: 0,
          textStyle: {
            color: 'rgba(255,255,255,1)',
            fontSize: 12
          },
          itemWidth: 12,
          itemHeight: 12
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'shadow'
          },
          show: true
        },
        xAxis: {
          type: 'category',
          axisLabel: {
            color: 'rgba(255,255,255,.45)'
          },
          axisTick: {
            show: false
          }
        },
        yAxis: [
          {
            type: 'value',
            name: '',
            nameTextStyle: {
              color: 'rgba(255,255,255,.45)'
            },
            axisLabel: {
              color: 'rgba(255,255,255,.45)'
            },
            splitLine: { // 在grid的分割线
              lineStyle: {
                color: 'rgba(255,255,255,.1)'
              }
            },
            axisTick: { // 坐标刻度
              show: false
            }
          }
        ]
      }
      return option
    },
    getChartsOptionsType1 (seriesType, yAxisType) { // bar + line，双y数值轴
      const option = {
        grid: {
          right: 50
        },
        tooltip: {
          formatter: (params) => {
            let html = ''
            html += `<b>${params[0].name}</b><br>`
            for (let i = 0; i < params.length; i++) {
              html += `${params[i].marker + params[i].seriesName}：${params[i].value}`
              html += `${params[i].seriesName === '完成率' ? '%' : (params[i].seriesName.indexOf('坪效') !== -1 ? '元' : '万元')}<br>`
            }
            return html
          }
        },
        yAxis: [
          {
            name: '万元',
            type: 'value'
          },
          {
            type: 'value',
            name: ' ',
            nameTextStyle: {
              color: 'rgba(255,255,255,.45)'
            },
            minInterval: 0.01,
            axisLabel: {
              color: 'rgba(255,255,255,.45)'
            },
            splitLine: { // 在grid的分割线
              show: false
            },
            axisTick: { // 坐标刻度
              show: false
            }
          }
        ],
        color: ['#0FC967', '#22D6FE'],
        series: [
          {
            type: 'bar',
            barWidth: 10
          },
          {
            type: 'line',
            yAxisIndex: 1,
            symbolSize: 6,
            color: '#FFA134'
          }
        ]
      }
      if (seriesType === 1) {
        option.series = [
          {
            name: '',
            data: [],
            stack: 'total',
            type: 'bar',
            barWidth: 10
          },
          {
            name: '',
            data: [],
            stack: 'total',
            type: 'bar',
            color: '#FFA134',
            barWidth: 10
          },
          {
            name: '',
            yAxisIndex: 1,
            symbolSize: 6,
            data: [],
            type: 'line',
            color: '#1A8CF2'
          }
        ]
      }
      if (yAxisType === 1) {
        option.yAxis[1].axisLabel.formatter = '{value}%'
      }
      return option
    },
    getChartsOptionsType4 (resData) { // 最大值默认高亮pie
      const seriesData = []
      for (let i = 0; i < resData.labels.length; i++) {
        seriesData.push(
          {
            value: resData.values[i],
            name: resData.labels[i]
          }
        )
      }
      let maxItem = seriesData[0]
      for (let i = 0; i < seriesData.length; i++) {
        if (maxItem.value < seriesData[i].value) {
          maxItem = seriesData[i]
        }
      }
      const option = {
        tooltip: {
          show: false
        },
        legend: {
          show: true,
          width: 205,
          itemWidth: 10,
          itemHeight: 10,
          right: 10,
          itemGap: 8,
          top: 'middle',
          textStyle: {
            color: 'rgba(255, 255, 255, 1)'
          }
        },
        color: ['#1BD2FB', '#34A6F5', '#237FC0', '#4451B1'],
        series: [
          {
            name: '',
            type: 'pie',
            radius: ['58%', '80%'], // 饼图大小
            center: ['25%', '55%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                formatter: [
                  '{a|{c}}',
                  '{b|{b} {d}%}'
                ].join('\n'),
                rich: {
                  a: {
                    color: '#fff',
                    fontSize: 30
                  },
                  b: {
                    color: 'rgba(255,255,255,.8)',
                    fontSize: 14
                  }
                }
              }
            },
            legendHoverLink: false, // 移入leged不显示在中间
            labelLine: {
              show: false
            },
            data: seriesData
          }
        ]
      }
      this.chartsB.setOption(option)
      this.chartsB.dispatchAction({
        type: 'highlight',
        name: maxItem.name
      })
      let currentName = maxItem.name
      const validNumList = [] // 非零的项集合
      for (let i = 0; i < seriesData.length; i++) {
        const item = seriesData[i]
        if (item.value) {
          validNumList.push(item.name)
        }
      }
      let selectedList = []
      this.chartsB.on('legendselectchanged', params => {
        selectedList = []
        this.chartsB.dispatchAction({
          type: 'downplay',
          name: currentName
        })
        for (const key in params.selected) {
          if (params.selected[key]) {
            selectedList.push(key)
          }
        }
        if (selectedList.length) {
          if (validNumList.length) {
            const validSelectedList = [] // legend中选中的非零的项集合
            for (let i = 0; i < validNumList.length; i++) {
              const item = validNumList[i]
              if (selectedList.includes(item)) {
                validSelectedList.push(item)
              }
            }
            if (validSelectedList.length) {
              currentName = validSelectedList[0]
            } else {
              currentName = selectedList[0]
            }
          } else {
            currentName = selectedList[0]
          }
          this.chartsB.dispatchAction({
            type: 'highlight',
            name: currentName
          })
        }
      })
      this.chartsB.on('mouseover', params => {
        this.chartsB.dispatchAction({
          type: 'downplay',
          name: currentName
        })
        currentName = params.name
        this.chartsB.dispatchAction({
          type: 'highlight',
          name: params.name
        })
      })
      // 鼠标移出
      this.chartsB.on('mouseout', params => {
        this.chartsB.dispatchAction({ // 持续高亮
          type: 'highlight',
          name: currentName
        })
      })
    },
    getChartsOptionsType3 () { // 类型4：普通bar + 离散类目y轴&数值x轴
      const option = {
        grid: {
          top: 20,
          right: 50,
          left: 80,
          bottom: 30
        },
        xAxis: {
          name: '万元',
          nameTextStyle: {
            color: 'rgba(255,255,255,.45)'
          },
          type: 'value',
          axisLabel: {
            color: 'rgba(255,255,255,.45)'
          },
          splitLine: { // 在grid的分割线
            show: false
          },
          axisTick: { // 坐标刻度
            show: false
          }
        },
        color: ['#1A8CF2', '#0FC967', '#1BD2FB', '#661CF4', '#B74EDB', '#FB6C14', '#FFA134', '#15FFA4'],
        yAxis: {
          type: 'category',
          axisLabel: {
            color: 'rgba(255,255,255,.45)'
          },
          axisTick: {
            show: false
          }
        },
        series: [
          {
            type: 'bar',
            label: {
              show: true,
              position: [5, 2],
              color: '#fff'
            },
            barWidth: 14
          }
        ]
      }
      return option
    },
    getChartsOptionsType0 (unitIndex) { // 双bar
      const unitList = ['', '万元', '㎡', '天']
      const option = {
        yAxis: [
          {
            name: unitList[unitIndex]
          }
        ],
        tooltip: {
          formatter: (params) => {
            let html = ''
            html += `<b>${params[0].name}</b><br>`
            for (let i = 0; i < params.length; i++) {
              html += `${params[i].marker + params[i].seriesName}：${params[i].value + unitList[unitIndex]}<br>`
            }
            return html
          }
        },
        color: ['#0FC967', '#22D6FE'],
        series: [
          {
            type: 'bar',
            barWidth: 10
          },
          {
            type: 'bar',
            barWidth: 10
          }
        ]
      }
      return option
    },
    // 计算最大值
    calMax (arr) {
      const max = Math.max(...arr)
      const maxint = Math.ceil(max / 9.5) // 不让最高的值超过最上面的刻度
      let maxval = maxint * 10 // 让显示的刻度是整数

      // 为了防止数据为0时，Y轴不显示，给个最大值
      if (maxval === 0) { maxval = 1 }
      return maxval
    },
    // 计算最小值
    calMin (arr) {
      const min = Math.min(...arr)
      const minint = Math.floor(min / 10)
      const minval = minint * 10// 让显示的刻度是整数
      return minval
    }
  }
}
</script>

<style lang="stylus" scoped>
.visual-store-analysis
  min-height calc(100vh - 210px)
  width 100%
  margin 23px 30px 23px 30px
  color #fff
  .page-header
    position relative
    .page-title
      display inline-block
      font-size 28px
      font-weight bold
      color #FFA134
      padding-right 21px
    .border-r
      position relative
      top 5px
      &:after
        position absolute
        top 2px
        right 0
        display inline-block
        content ''
        height 32px
        width 1px
        background-color rgba(255, 255, 255, 0.1)
    .view-menu-title
      font-size 18px
      margin-left 21px
      cursor pointer
      &:hover
        color #FFA134
      .el-icon-menu
        position relative
        top 2px
        &:before
          font-size 20px
    .view-menu
      width 420px
      height 152px
      padding 16px 0 0 20px
      box-sizing border-box
      position absolute
      left 235px
      top 42px
      background #272930
      box-shadow 0px 12px 35px 0px rgba(0, 0, 0, 0.3)
      border-radius 7px 8px 8px 7px
      z-index 1111
      h2
        font-weight 500
      ul
        margin-top 19px
        li
          display inline-block
          width 114px
          height 28px
          line-height 28px
          text-align center
          margin 0 16px 16px 0
          border 1px solid rgba(255, 255, 255, 0.3)
          border-radius 3px
          cursor pointer
          font-size 14px
          &:hover, &.active-item
            color #FFA134
            border 1px solid #FFA134
          &:nth-child(3n)
            margin 0 0 16px 0 !important
    .w-input-sty
      margin 3px 60px 0 0
  .page-body
    margin-top 32px
    .box-0
      max-width 442px
      min-height 238px
      margin-bottom 12px
      position relative
    .box-higher
      height 245px
    .building-select
      position absolute
      right 0
      top 0
    .shadow-box
      background #23262D
      box-shadow inset 0px 0px 10px 0px rgba(176, 191, 226, 0.15)
      border-radius 2px
      position relative
    .box-main
      position relative
      width calc(100% - 514px)
      height 995px
      margin-right 59px
      background #1E1E23
      border 1px solid rgba(255, 255, 255, 0.1)
      box-shadow 0px 9px 24px 0px rgba(0, 0, 0, 0.32)
      border-radius 2px
      box-sizing border-box
    .info-list
      li:nth-child(2n+1)
        margin-left 50px
        width 183px
      li
        float left
        text-align center
        margin-top 24px
        font-weight 500
        color #70747D
        width 183px
        p
          font-size 14px
          text-align left
          span
            margin 0 auto
            color #fff
            font-size 26px
            font-weight bold
          .xl-span
            display inline-block
            font-size 12px
            font-weight 400
            transform scale(0.83)
          .block-span
            display block
            margin-left -10px
    .charts-box
      width 100%
      height 200px
    .tbs-wrap
      width 98%
      padding 6px 15px 20px 15px
      max-height 205px
      margin 0 auto
      overflow-y auto
    .merchantsSchedule>>>
      .el-carousel__container
        height 200px
      .el-table td, .el-table th
        padding 9px 0
.hide
  display none
.tipname
  position absolute
  background rgba(0, 0, 0, 0.5)
  border-radius 5px
  max-width 400px
  padding 5px
  z-index 1
  color #fff
.details-no-data
  margin-top 40px
.extra-style>>>
  .el-input__inner
    width auto
    font-size 16px
    border none !important
    padding 0
  .el-input--suffix
    position relative
    top -6px
.xs-select>>>
  .el-input--suffix
    width 90px !important
  .el-input__inner
    font-size 14px !important
</style>
