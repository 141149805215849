<template>
  <div class="map-view-wrap">
    <div class="view-tab">
      <ul class="data-tabs">
        <li
          :class="{'active-item': index === item.value}"
          v-for="item in dataTabsList"
          :key="item.value"
          @click="handleDataTabs(item)"
        >
          {{item.name}}
        </li>
      </ul>
    </div>
    <div class="map-building-select w-input-sty">
      <el-select
        popper-class="w-block-select-down"
        v-model="buildingId"
        @change="changebuilding"
        placeholder="请选择"
      >
        <el-option
          v-for="item in buildingList"
          :key="item.buildingCode"
          :label="item.buildingName"
          :value="item.buildingCode"
        >
        </el-option>
      </el-select>
    </div>
    <iframe
      :src="iframeUrl"
      width="100%"
      height="800px"
      frameborder="0"
    ></iframe>
    <div
      class="positon-a plan-icon-list"
      v-if="viewVal === 1"
    >
      <div v-if="index === 0">
        <template v-for="item in iconList">
          <img
            :key="item.industry"
            :src="item.img"
            alt=""
          >{{item.industry}}
        </template>
      </div>
      <div
        class="map-font-color"
        v-if="index === 1"
      >
        <span><i style="background: rgba(81, 45, 101, 1);"></i>待招商</span>
        <span><i style="background: rgba(161, 105, 40, 1);"></i>待跟进</span>
        <span><i style="background: rgba(52, 70, 180, 1);"></i>已联系</span>
        <span><i style="background: rgba(14, 153, 151, 1);"></i>已看场</span>
        <span><i style="background: rgba(148, 140, 49, 1);"></i>已报价</span>
        <span><i style="background: rgba(143, 63, 22, 1);"></i>已预定</span>
        <span><i style="background: rgba(9, 151, 70, 1);"></i>已签约</span>
        <span><i style="background: rgba(153, 153, 153, 1);"></i>已搁置/无意向</span>
      </div>
    </div>
    <div
      class="plan-icon-list2"
      v-if="viewVal === 2"
    >
      <div v-if="index === 0">
        <span
          class="dot"
          style="background: #A34D1A;"
        ></span>铺位年度累计租金&lt;租决计算租金
        <span
          class="dot"
          style="background: #155027;"
        ></span>铺位年度累计租金≥租决计算租金
      </div>
      <div v-if="index === 1">
        <span
          class="dot"
          style="background: #938E95;"
        ></span>铺位未签约
        <span
          class="dot"
          style="background: #155027;"
        ></span>铺位合同剩余＞90天
        <span
          class="dot"
          style="background: #A56D2D;"
        ></span>铺位合同剩余60≤90天
        <span
          class="dot"
          style="background: #626218;"
        ></span>铺位合同剩余30≤60天
        <span
          class="dot"
          style="background: #A34D1A;"
        ></span>铺位合同剩余≤30天
      </div>
    </div>
    <div
      class="plan-icon-list3"
      v-if="viewVal === 3"
    >
      <div v-if="index === 0">
        <span class="gradual1"></span>铺位年度租金由高到低
      </div>
      <div v-if="index === 1">
        <span class="gradual2"></span>铺位平均租金坪效由高到低
      </div>
    </div>
    <div
      class="plan-icon-list3"
      v-if="viewVal === 4"
    >
      <div v-if="index === 0">
        <span class="gradual1"></span>铺位年度销售额由高到低
      </div>
      <div v-if="index === 1">
        <span class="gradual2"></span>年度平均销售坪效由高到低
      </div>
      <div v-if="index === 2">
        <span class="gradual3"></span>铺位平均租售比由高到低
      </div>
    </div>
    <div
      class="plan-icon-list3"
      v-if="viewVal === 5"
    >
      <div v-if="index === 0">
        <span class="gradual2"></span>本年度账单收缴率由高到低
      </div>
      <div v-if="index === 1">
        <span class="gradual1 gradual1-1"></span>本年度欠费金额由高到低
      </div>
      <div v-if="index === 2">
        <span class="gradual3"></span>铺位最长欠费账龄比由高到低
      </div>
    </div>
    <div
      class="plan-icon-list3"
      v-if="viewVal === 6"
    >
      <div>
        <span class="gradual1"></span>铺位留存时长由长到短
      </div>
      <div>
        <span class="gradual2"></span>铺位空置时长由长到短
      </div>
    </div>
    <shop-drawer
      v-if="drawer"
      :viewVal="viewVal"
      :berthId="berthId"
      :filtroProject="filtroProject"
      :buildingName="buildingName"
      :dataObj="dataObj"
      @hideShopDetails="hideShopDetails"
    ></shop-drawer>
  </div>
</template>

<script>
import api from '@/api'
import shopDrawer from '@/views/biModules/components/shopDrawer.vue'
import { getCookie } from '@/utils'
export default {
  data () {
    return {
      index: 0,
      dataTabsList: [],
      buildingId: '',
      buildingName: '',
      buildingList: [],
      iframeUrl: '',
      planId: 0,
      drawer: false,
      iconList: [],
      dataObj: {},
      berthId: ''
    }
  },
  components: {
    shopDrawer
  },
  props: {
    filtroProject: {
      type: Number,
      default: 0
    },
    viewVal: {
      type: Number,
      default: 1
    }
  },
  watch: {
    filtroProject (n, o) {
      this.loadData()
    },
    viewVal (n, o) {
      this.loadData()
      this.drawer = false
    }
  },
  beforeDestroy () {
    window.removeEventListener('message', this.postMessage)
  },
  mounted () {
    // 重载地图的情况
    // 1. 进入/刷新页面
    // 2. 切换视图
    // 3. 切换地图模块顶部tbs
    // 4. 切换楼宇
    this.loadData()
  },
  methods: {
    loadData () {
      this.getViewStatus() // 地图模块tabs
      this.getGdBuildingFunc() // 楼宇
      this.getIndustryIconFunc() // 业态图标
      window.addEventListener('message', this.postMessage, false)
    },
    getIndustryIconFunc () {
      this.axios.post(api.getIndustryIcon)
        .then((res) => {
          this.iconList = res.data.data
        })
    },
    hideShopDetails () {
      this.drawer = false
      document.body.style.overflow = ''
    },
    postMessage (e) {
      if (e.data.ss) {
        this.berthId = e.data.ss
        this.getDataBerthInfo()
      }
    },
    getDataBerthInfo () { // 铺位信息
      const params = {
        berthMapId: this.berthId,
        projectId: this.filtroProject
      }
      this.axios.post(api.berthInfo, params).then(res => {
        if (res.data.code === 0) {
          const resData = res.data.data
          this.dataObj = {}
          if (resData) {
            this.dataObj = resData
            this.drawer = true
            document.body.style.overflow = 'hidden'
          }
        }
      }, rej => {
        console.log(rej)
      }).catch(err => {
        console.log(err)
      })
    },
    getGdBuildingFunc () { // 获取楼宇信息
      this.axios.post(api.getGdbuilding, { projectId: this.filtroProject })
        .then((res) => {
          if (res.data.data && res.data.data.length > 0) {
            this.buildingId = res.data.data[0].buildingCode
            this.buildingName = res.data.data[0].buildingName
          }
          this.buildingList = res.data.data
          this.$emit('setBuildingList', this.buildingList) // 商铺可视化页面楼宇选择器
          if (this.buildingId) {
            this.reloadMap()
          }
        })
    },
    changebuilding (val) { // 切换楼宇
      const list = this.buildingList.filter(item => {
        return item.buildingCode === val
      })
      if (list.length) {
        this.buildingName = list[0].buildingName
      }
      this.reloadMap()
    },
    handleDataTabs (item) { // 切换地图上部tabs
      this.index = item.value
      this.reloadMap()
    },
    reloadMap () {
      let temUrl = ''
      if (process.env.NODE_ENV === 'development') {
        temUrl = 'http://localhost:8001'
      } else if (window.location.href.indexOf('http://') > -1) {
        temUrl = 'http://' + document.domain + '/map'
      } else {
        temUrl = 'https://' + document.domain + '/map'
      }
      this.iframeUrl = temUrl + '/viewMap.html?token=' +
        getCookie('wisdom_token') +
        '&taskId=' + this.planId +
        '&projectId=' + this.filtroProject +
        '&viewVal=' + this.viewVal +
        '&mapType=' + this.index +
        '&buildingId=' + this.buildingId
    },
    getViewStatus () {
      if (this.viewVal === 1) {
        this.dataTabsList = [
          {
            name: '规划业态',
            value: 0
          },
          {
            name: '招商进度',
            value: 1
          }
        ]
      } else if (this.viewVal === 2) {
        this.dataTabsList = [
          {
            name: '合同租决',
            value: 0
          },
          {
            name: '合同时长',
            value: 1
          }
        ]
      } else if (this.viewVal === 3) {
        this.dataTabsList = [
          {
            name: '年度租金贡献',
            value: 0
          },
          {
            name: '年度租金坪效',
            value: 1
          }
        ]
      } else if (this.viewVal === 4) {
        this.dataTabsList = [
          {
            name: '销售额',
            value: 0
          },
          {
            name: '销售坪效',
            value: 1
          },
          {
            name: '租售比',
            value: 2
          }
        ]
      } else if (this.viewVal === 5) {
        this.dataTabsList = [
          {
            name: '账单收缴',
            value: 0
          },
          {
            name: '欠费金额',
            value: 1
          },
          {
            name: '欠费账龄',
            value: 2
          }
        ]
      } else if (this.viewVal === 6) {
        this.dataTabsList = [
          {
            name: '空置留存',
            value: 0
          }
        ]
      }
      this.index = 0
    }
  }
}
</script>

<style lang="stylus" scoped>
.map-view-wrap
  position relative
  height 986px
  .view-tab
    display flex
    justify-content center
    padding-top 24px
    .data-tabs
      text-align center
      li
        display inline-block
        width 116px
        height 30px
        line-height 30px
        background #1E1E23
        border 1px solid rgba(255, 255, 255, 0.23)
        cursor pointer
        font-size 14px
        &:first-child
          border-radius 3px 0px 0px 3px
        &:last-child
          border-radius 0px 3px 3px 0px
        &.active-item
          background #FFA134
          border-color #FFA134
.plan-icon-list
  font-size 14px
  color #fff
  font-weight 500
  margin 10px auto
  text-align center
  img
    margin-right 5px
    margin-left 10px
    width 18px
    height 18px
    vertical-align middle
  .map-font-color
    text-align center
    color #fff
    font-size 14px
    font-weight 500
    span
      margin 0 8px
      i
        display inline-block
        width 12px
        height 12px
        border-radius 6px
        vertical-align middle
        margin-right 4px
.plan-icon-list2
  display flex
  justify-content center
  font-size 14px
  .dot
    display inline-block
    width 14px
    height 14px
    background #A34D1A
    border-radius 50%
    margin-right 5px
    vertical-align middle
    margin-left 10px
.plan-icon-list3
  display flex
  justify-content center
  font-size 14px
  div
    margin 0 10px
  .gradual1
    display inline-block
    width 126px
    height 14px
    background linear-gradient(90deg, #1EB8DA 1%, #0D404C 100%)
    border-radius 7px
    margin-right 10px
  .gradual1-1
    background linear-gradient(90deg, #6d8fa8 1%, #162B3B 100%) !important
  .gradual2
    display inline-block
    width 126px
    height 14px
    background linear-gradient(90deg, #0FAD59 1%, #0B3C22 100%)
    border-radius 7px
    margin-right 10px
  .gradual3
    display inline-block
    width 126px
    height 14px
    background linear-gradient(90deg, #CB5C1A 1%, #6A3719 100%)
    border-radius 7px
    margin-right 10px
</style>
